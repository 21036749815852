import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import Layout from '../../layouts'

const PageOver = ({ data }) => {
  const page = data.page
  return (
    <Layout>
      <div id="start" className="content">
      <Helmet>
        <title>Over Sureventure Camperbouw</title>
        <meta name="description" content={page.metaDescription} />
      </Helmet>
        <section className="panel content-text">
          {page.title &&
            <h1>{page.title}</h1>
          }
          {page.intro &&
            <div className="intro">{page.intro}</div>
          }
          {page._rawBody &&
            <BlockContent blocks={page._rawBody} />
          }
        </section>

        <section className="panel panel--xlarge">
          {page.personen && page.personen.map(function(person, i){
            const personClass = i % 2 ? "person block row order-odd" : "person block row order-even";
            return (
              <div key={i} className={personClass}>
                <div className="small-12 medium-6 columns content-img">
                  {person.afbeelding &&
                    <Img fluid={person.afbeelding.asset.fluid} alt={person.naam} />
                  }
                </div>
                <div className="small-12 medium-6 columns content-text">
                  <h2>{person.naam}</h2>
                  {person._rawDescriptionShort &&
                    <BlockContent blocks={person._rawDescriptionShort} />
                  }
                </div>
              </div>
            );
          })}
        </section>

      </div>
    </Layout>
  )
}

export default PageOver

export const query = graphql`
  query {
    page: sanityPageAbout {
      title
      metaDescription
      intro
      _rawBody
      personen {
        naam
        afbeelding {
          asset {
            fluid(maxWidth: 780) {
              base64
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              aspectRatio
            }
          }
        }
        _rawDescriptionShort
      }
    }
  }
`

